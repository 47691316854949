<template>
  <a-modal
    v-model:visible="visible"
    centered
    :closable="false"
    title="Switch Organization & Role"
    :footer="null"
    destroyOnClose
  >
    <org-switch-modal-vue
      :organizations="organizationList"
      :roles="rolesList"
      :email="email"
      @close="toggleModal"
      @refresh="updateList"
    >
    </org-switch-modal-vue>
  </a-modal>
  <a-tabs :onChange="handleChange" v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="Users">
      <a-table
        :columns="columns"
        :data-source="filterUser"
        bordered
        :scroll="{ y: '50vh', x: '900px' }"
        :key="tableKey"
      >
        <template #title>
          <div>
            <a-button
              class="float-right mb-1 d-flex align-items-center"
              type="primary"
              @click="handleShowForm"
              id="add-user-btn"
            >
              <template #icon
                ><i class="bi bi-person-plus mr-2 mb-1"></i
              ></template>
              Add User
            </a-button>

            <a-modal
              v-model:visible="showForm"
              centered
              :closable="false"
              title="Add user"
              :footer="null"
              id="add-user-modal"
              destroyOnClose
            >
              <add-user-new
                :organizations="organizationList"
                :roles="roles"
                @addUser="addUser"
                @cancel="handleShowForm"
                :isLoading="isLoading"
              ></add-user-new>
            </a-modal>
          </div>

          <a-typography-title :level="5">
            Users: &nbsp;
            <a-tag color="blue">{{ listLength }}</a-tag>
            <a-input
              v-model:value="searchValue"
              class="w-25"
              placeholder="Search User"
            />
          </a-typography-title>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'actions'">
            <div class="actions">
              <span>
                <a-popconfirm
                  title="Are you sure? you want to delete this user"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteData(record.email)"
                >
                  <a-button type="primary" danger>Delete</a-button>
                </a-popconfirm>

                <a-button
                  class="ml-2"
                  type="primary"
                  @click="toggleModal(record.email)"
                  >Switch Org</a-button
                >
              </span>
            </div>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
    <a-tab-pane
      v-if="isAdmin"
      key="2"
      tab="Switched Users"
      :force-render="true"
    >
      <SwitchedUsersVue :key="componentKey"></SwitchedUsersVue>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import DialogComponent from '../../../shared/Components/Dialog.vue';
import httpClient from '../../../../service/httpClient.js';
import UserServices from '../../../../services/user.js';
import AuthServices from 'src/services/auth';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import { SearchOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs } from 'vue';
import SwitchedUsersVue from './SwitchedUsers.vue';
import OrgSwitchModalVue from './OrgSwitchModal.vue';
import AddUserNew from './AddUser.vue';
export default {
  name: 'approvedUser-component',
  components: {
    DialogComponent,
    OrgSwitchModalVue,
    SwitchedUsersVue,
    SearchOutlined,
    AddUserNew
  },
  setup() {
    const searchInput = ref();
    const componentKey = ref(0);
    const visible = ref(false);
    const toast = useToast();
    const handleOk = e => {
      visible.value = false;
    };
    const state = reactive({
      searchText: '',
      searchedColumn: ''
    });
    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //   console.log(selectedKeys);
    //   confirm();
    //   // state.searchText = selectedKeys[0];
    //   // state.searchedColumn = dataIndex;
    // };

    const handleReset = clearFilters => {
      clearFilters();
      state.searchText = '';
    };

    return {
      componentKey,
      // handleSearch,
      handleReset,
      toast,
      visible,
      handleOk,
      activeKey: ref('1'),
      ...toRefs(state)
    };
  },
  data() {
    return {
      columns: [
        {
          title: 'First Name',
          dataIndex: 'username',
          key: 'name',
          width: '10%'
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
          width: '10%'
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width: '10%'
        },
        {
          title: 'Role',
          dataIndex: 'role',
          width: '10%',
          // key: 'role',
          filters: [
            { text: 'Admin', value: 'admin' },
            { text: 'Org Admin', value: 'org_admin' },
            { text: 'Labeler', value: 'labeler' },
            { text: 'Org User', value: 'org_user' },
            { text: 'Analytics User', value: 'analytics_user' },
            { text: 'Workstation User', value: 'workstation_user' },
            { text: 'Doc User', value: 'doc_user' }
          ],
          filteredValue: this.selectedRole,
          filterMultiple: false,
          onFilter: (value, record) => {
            this.selectedRole = [value];
          }
        },

        {
          title: 'Organization',
          dataIndex: 'Organization',
          width: '10%'
        },
        {
          title: 'Actions',
          dataIndex: 'actions',
          width: '15%'
        }
      ],
      isAdmin: null,
      email: null,
      list: [], // approved users list
      allRoles: {},
      roleList: [],
      definedRoles: [
        { text: 'Admin', value: 'admin' },
        { text: 'Org Admin', value: 'org_admin' },
        { text: 'Labeler', value: 'labeler' },
        { text: 'Org User', value: 'org_user' },
        { text: 'Analytics User', value: 'analytics_user' },
        { text: 'Workstation User', value: 'workstation_user' },
        { text: 'Doc User', value: 'doc_user' }
      ],
      userDeleteMsg: '',
      userToDelete: null,
      isComfirm: null,
      showForm: false,
      isLoading: false,
      isFetchingUsers: false,
      newList: [],
      isError: false,
      searchValue: '',
      listLength: 0,
      selectedRole: null,
      tableKey: 0
    };
  },
  computed: {
    ...mapGetters(['roles', 'organizations']),

    filterUser() {
      if (this.searchValue) {
        const inputValue = this.searchValue.toLowerCase();
        const keys = ['Organization', 'role', 'username', 'lastname', 'email'];

        let filteredList = this.list.filter(obj =>
          keys.some(key => String(obj[key]).includes(inputValue))
        );
        this.listLength = filteredList.length;
        return filteredList;
      } else {
        this.listLength = this.list.length;
        return this.list;
      }
    }
  },
  watch: {
    filterUser() {},
    async selectedRole(value) {
      await this.getData();
    },
    roles(value) {
      if (value.length >= 1) {
        this.rolesList = value.map(el => ({
          value: el.id,
          label: el.name
        }));
      }
    },
    organizations(value) {
      if (value.length >= 1) {
        this.organizationList = value.map(el => ({
          value: el.Org_name,
          label: el.Org_name
        }));
      }
    },
    list() {
      this.tableKey += 1;
    }
  },
  async created() {
    this.setPermission();
    this.getOrganizations();
    this.getAllRoles();
    await this.getRoles();
    this.updateList();
    this.fetchUsersAndRoles();
  },
  mounted() {},
  methods: {
    ...mapActions([
      'getAllRoles',
      'getPendingUsersList',
      'getOrganizations',
      'fetchAdminPermissions'
    ]),
    async updateList() {
      await this.getData();
      this.filterUser; // Accessing the computed property triggers re-computation
    },
    async fetchUsersAndRoles() {
      try {
        this.isFetchingUsers = true;
        const [roles, users] = await Promise.all([
          this.fetchRoles(),
          this.fetchUsers()
        ]);
        this.roleList = roles;

        const tempRoles = roles.reduce((res, el) => {
          res[el.id] = roleLabels[el.name];
          return res;
        }, {});

        this.newList = users.map(u => ({ ...u, role: tempRoles[u.role] }));
      } catch (error) {
        console.log('error in users list:', error);
      } finally {
        this.isFetchingUsers = false;
      }
    },
    async setPermission() {
      if (await this.fetchAdminPermissions()) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
    handleChange(selectedkey) {
      this.activeKey = selectedkey;
      if (selectedkey == '1') {
        // this.getData();
        this.updateList();
      } else {
        this.componentKey += 1;
      }
    },
    toggleModal(email) {
      if (email) {
        this.email = email;
      }
      this.visible = !this.visible;
    },
    async getData(userFilter) {
      this.list = [];
      const [error, data] = !this.selectedRole
        ? await UserServices.allUsers(userFilter)
        : await UserServices.getRoleUsers(this.selectedRole);
      if (error) {
        console.log({ error });
        return;
      }
      this.list = [...data];
      // this.list.forEach((user) => {
      //   user['role'] = this.allRoles[user['role']];
      // });
      this.list.forEach(user => {
        const foundRole = this.definedRoles.find(
          role => role.value === this.allRoles[user['role']]
        );
        if (foundRole) {
          user.role = foundRole.text;
        } else {
          user.role = 'Unknown Role';
        }
      });
      this.listLength = this.list.length;
    },

    getRoles() {
      return new Promise(async resolve => {
        this.allRoles = {};
        const [error, data] = await UserServices.allRoles();
        if (error) {
          console.log({ error });
          return;
        }
        this.allRoles = data;
        this.allRoles = data.reduce((res, el) => {
          res[el.id] = el.name;
          return res;
        }, {});
        // this.roleList = Object.entries(this.allRoles).map(([key, value]) => ({
        //   text: value,
        //   value: value,
        // }));
        resolve();
      });
    },

    async deleteData(userEmail) {
      const [error, data] = await UserServices.deleteUser(userEmail);
      if (error) {
        console.log({ error });
        return;
      }
      // this.getData();
      this.updateList();
    },
    fetchUsers() {
      return new Promise(async (resolve, reject) => {
        try {
          const orgUsers = await this.getUsers(roles.org_user);
          const analyticsUsers = await this.getUsers(roles.analytics_user);
          const workstationUsers = await this.getUsers(roles.workstation_user);
          const users = [
            ...orgUsers,
            ...analyticsUsers,
            ...workstationUsers
          ].sort((a, b) => a.id - b.id);
          return resolve(users);
        } catch (error) {
          reject();
        }
      });
    },
    async fetchRoles() {
      return await httpClient.get('account/roles/', false);
    },
    handleShowForm() {
      this.showForm = !this.showForm;
    },
    async addUser(payload) {
      const [error, data] = await AuthServices.AddOrgAdmin(payload);
      if (error) {
        this.toast.error('User already exists');
        return;
      }
      this.toast.success(data.response);
      this.handleShowForm();
    }
  }
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 6px;
}

.error-msg {
  color: red;
}

.error-box {
  width: 140px;
  margin-left: 20px;
}

.custom-switch {
  background-color: green;
}
</style>
