import apiHelper from './index';
import { getAuthConfig } from './config';

const allUsers = async (spinner = true) => {
  return await apiHelper.get(
    'account/users_list?is_admin=false&is_active=true&role__name=org_admin',
    spinner
  );
};

const allRoles = async (spinner = true) => {
  return await apiHelper.get('account/roles/', spinner);
};

const deleteUser = async (userEmail, spinner = true) => {
  return await apiHelper.remove(`account/update-delete/${userEmail}/`, spinner);
};

const getRoleUsers = async (role, spinner = true) => {
  return await apiHelper.get(
    `account/users_list?is_admin=false&is_active=true&role__name=${role}&is_org_switch=false`,
    spinner
  );
};
const switchedUsers = async (spinner = true) => {
  return await apiHelper.get(
    'account/users_list?is_admin=false&is_active=true&is_org_switch=true',
    spinner
  );
};

const switchUser = async (userEmail, spinner = true) => {
  return await apiHelper.patch(
    `account/update-switch/${userEmail}/`,
    spinner,
    getAuthConfig()
  );
};

const updateUser = async (userEmail, payload, spinner = true) => {
    return await apiHelper.patch(
        `account/update-delete/${userEmail}/`,
        payload,
        spinner
    );
};

export default {
  allUsers,
  allRoles,
  deleteUser,
  getRoleUsers,
  switchedUsers,
  switchUser,
  updateUser,
};
