<template>
  <div class="p-4">
    <ApprovedUsers />
  </div>
</template>
<script>
import ApprovedUsers from './ApprovedUsers.vue';

export default {
  components: {
    ApprovedUsers,
  },
};
</script>
