<template>
    <a-form
      layout="vertical"
      :model="form"
      ref="formRef"
      name="approve_user"
      class="user-form"
      :rules="validationsRules()"
      @finish="onSubmit"
    >
      <div class="row">
        <div class="col-sm-12">
          <a-form-item label="Select Organization" name="organization">
            <a-select
              class="w-100"
              show-search
              v-model:value="form.organization"
              placeholder="Select Organization"
              :options="organizations"
            ></a-select>
          </a-form-item>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <a-form-item label="Select Role" name="role">
            <a-select
              class="w-100"
              show-search
              v-model:value="form.role"
              placeholder="Select Role"
              :options="roles"
            ></a-select>
          </a-form-item>
        </div>
      </div>

      <div class="text-right">
        <a-button @click="onCancel()">
          Cancel
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          class="ml-2"
          :loading="loading"
        >
          Save
        </a-button>
      </div>
    </a-form>
  </template>
  <script>
  import { mapActions } from 'vuex';
  import { ref } from 'vue';

  import { useToast } from 'vue-toastification';
  export default {
    props: ['organizations', 'roles', 'email'],
    emits: ['close', 'refresh'],
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        loading: false,
        form: {
          role: '',
          organization: '',
        },
      };
    },
    methods: {
      ...mapActions(['switchOrganization']),
      validationsRules() {
        return {
          role: [
            {
              required: true,
              trigger: 'change',
              message: 'role is required',
            },
          ],
          organization: [
            {
              required: true,
              trigger: 'change',
              message: 'Organization is required',
            },
          ],
        };
      },
      async onSubmit() {
        this.loading = true;
        const data = {
          email: this.email,
          roleId: this.form.role,
          Organization_name: this.form.organization,
        };
        if (await this.switchOrganization(data)) {
          this.loading = false;
          this.$emit('refresh');
          this.$emit('close');
        } else {
          this.loading = false;
        }
      },
      onCancel() {
        this.$emit('close');
      },
    },
  };
  </script>
  <style scoped></style>
