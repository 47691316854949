<template>
    <a-table
      :columns="columns"
      :data-source="list"
      bordered
      :scroll="{ y: '50vh', x: '900px' }"
    >
      <template #title>
        <a-typography-title :level="5">
          Users: &nbsp;
          <a-tag color="blue">{{ list.length }}</a-tag>
        </a-typography-title>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'actions'">
          <div class="actions">
            <span>
              <a-popconfirm
                title="Are you sure? you want to switch the user back?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="switchUser(record.email)"
              >
                <a-button type="primary" danger>Switch back</a-button>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </template>
    </a-table>
  </template>
  <script>
  import DialogComponent from '../../../shared/Components/Dialog.vue';
  import httpClient from '../../../../service/httpClient.js';
  import UserServices from '../../../../services/user.js';
  import { useToast } from 'vue-toastification';
  import { mapActions, mapGetters } from 'vuex';
  import { ref } from 'vue';

  export default {
    name: 'approvedUser-component',
    components: {
      DialogComponent,
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    computed: {
      ...mapGetters(['roles', 'organizations']),
    },
    watch: {
      roles(value) {
        if (value.length >= 1) {
          this.rolesList = value.map((el) => ({
            value: el.id,
            label: el.name,
          }));
        }
      },
      organizations(value) {
        if (value.length >= 1) {
          this.organizationList = value.map((el) => ({
            value: el.Org_name,
            label: el.Org_name,
          }));
        }
      },
    },
    data() {
      return {
        columns: [
          {
            title: 'First Name',
            dataIndex: 'username',
          },
          {
            title: 'Last Name',
            dataIndex: 'lastname',
          },
          {
            title: 'Email',
            dataIndex: 'email',
          },
          {
            title: 'Role',
            dataIndex: 'role',
          },
          {
            title: 'Organization',
            dataIndex: 'Organization',
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
          },
        ],
        list: [], // approved users list
        allRoles: {},
        userDeleteMsg: '',
        userToDelete: null,
        isComfirm: null,
        isError: false,
      };
    },
    async created() {
      this.getOrganizations();
      this.getAllRoles();
      await this.getRoles();
      this.getData();
    },

    methods: {
      ...mapActions(['getAllRoles', 'getPendingUsersList', 'getOrganizations']),
      async getData() {
        this.list = [];
        const [error, data] = await UserServices.switchedUsers();
        if (error) {
          console.log({ error });
          return;
        }
        this.list = data;
        console.log(this.list);
        this.list.forEach((user) => {
          user['role'] = this.allRoles[user['role']];
        });
      },

      getRoles() {
        return new Promise(async (resolve) => {
          this.allRoles = {};
          const [error, data] = await UserServices.allRoles();
          if (error) {
            console.log({ error });
            return;
          }
          this.allRoles = data;
          this.allRoles = data.reduce((res, el) => {
            res[el.id] = el.name;
            return res;
          }, {});
          resolve();
        });
      },

      async switchUser(userEmail) {
        console.log('helol');
        const [error, data] = await UserServices.switchUser(userEmail);
        if (error) {
          return;
        }
        this.toast.success('User switched successfully');
        this.getData();
      },
    },
  };
  </script>

  <style scoped>
  .actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* margin: 0 6px; */
  }

  .error-msg {
    color: red;
  }
  </style>
